<template>
  <v-dialog
    scrollable
    v-model="changeStatus.dialog"
    max-width="400px"
    @click:outside="resetChangeStatus()"
  >
    <v-card>
      <v-card-title class="headline">Reject</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <div class="mb-5">Please select a reason for rejection</div>

        <v-select
          label="Reason"
          v-model="changeStatus.reason"
          :items="reasons"
          item-text="label"
          item-value="name"
          outlined
          background-color="white"
          :error="errors.hasOwnProperty('reason')"
          :error-messages="errors['reason']"
        ></v-select>

        <v-textarea
          label="Comment Text"
          type="textarea"
          v-model="changeStatus.comment_text"
          outlined
          background-color="white"
          :error="errors.hasOwnProperty('comment_text')"
          :error-messages="errors['comment_text']"
        ></v-textarea>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetChangeStatus()"
          >Cancel</v-btn
        >
        <v-btn
          color="accent"
          @click="changeStatusConfirmed()"
          :loading="changeStatus.loading"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    fromProperty: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      changeStatus: {
        form_status: null,
        from_property: false,
        errors: {},
        reason: null,
        dialog: false,
        loading: false,
        comment_text: null,
        form_id: null,
        group_id: null,
        property: null,
        type: null,
      },
      errors: {},
      isEditing: false,
      reasons: [
        { label: "Unaffordable", name: "Unaffordable" },
        { label: "Unsuitable", name: "Unsuitable" },
        { label: "Pet", name: "Pet" },
        { label: "Smoker", name: "Smoker" },
        { label: "Previous Issues", name: "Previous Issues" },
      ],
    };
  },

  methods: {
    rejectGroup(group = null) {
      this.changeStatus.group_id = group.id;
      this.changeStatus.dialog = true;
      this.changeStatus.type = "group";

      if (this.fromProperty) {
        this.changeStatus.from_property = true;
      }
    },

    rejectAllIfStatuses(property = null) {
      this.changeStatus.property = property;
      this.changeStatus.dialog = true;
      this.changeStatus.type = "all";

      if (this.fromProperty) {
        this.changeStatus.from_property = true;
      }
    },

    changeStatusPrompt(application = null, from = null) {
      this.changeStatus.form_id = application.id;
      this.changeStatus.dialog = true;
      this.changeStatus.type = "individual";

      if (from == "Individual Page") {
        this.isEditing = true;
      }

      if (this.fromProperty) {
        this.changeStatus.from_property = true;
      }
    },
    resetChangeStatus() {
      this.changeStatus.form_status = null;
      this.changeStatus.comment_text = null;
      this.changeStatus.errors = null;
      this.changeStatus.form_id = null;
      this.changeStatus.dialog = false;
      this.changeStatus.reason = null;
      this.changeStatus.from_property = false;
      this.changeStatus.loading = false;
      this.isEditing = false;
    },
    changeStatusConfirmed() {
      const appId = this.$route.params.id;
      const applicationId = this.changeStatus.form_id;
      const groupId = this.changeStatus.group_id;

      this.changeStatus.loading = true;
      let playloadUrl = null;

      if (this.changeStatus.type == "individual") {
        if (this.changeStatus.from_property) {
          playloadUrl = "sbpm/propertiesStore/rejectApplication";
        } else {
          playloadUrl = "sbpm/applicationsStore/rejectApplication";
        }

        this.$store
          .dispatch(playloadUrl, {
            appId,
            applicationId,
            comment_text: this.changeStatus.comment_text,
            reason: this.changeStatus.reason,
            isEditing: this.isEditing,
          })
          .then(() => {
            this.resetChangeStatus();
          })
          .catch((err) => {
            this.changeStatus.errors = err.response.data.errors;
            this.changeStatus.loading = false;
          });
      } else if (this.changeStatus.type == "all") {
        // let propertyId = this.changeStatus.property.id;

        const propertyId = this.$route.params.propertyId;

        if (this.changeStatus.from_property) {
          playloadUrl = "sbpm/propertiesStore/rejectAllIfStatus";
        } else {
          playloadUrl = "sbpm/applicationsStore/rejectApplication";
        }

        this.$store
          .dispatch(playloadUrl, {
            appId,
            propertyId,
            comment_text: this.changeStatus.comment_text,
            reason: this.changeStatus.reason,
            isEditing: this.isEditing,
          })
          .then(() => {
            location.reload();
          })
          .catch((err) => {
            this.changeStatus.errors = err.response.data.errors;
            this.changeStatus.loading = false;
          });
      } else {
        if (this.changeStatus.from_property) {
          playloadUrl = "sbpm/propertiesStore/rejectGroup";
        } else {
          playloadUrl = "sbpm/applicationsStore/rejectGroup";
        }

        this.$store
          .dispatch(playloadUrl, {
            appId,
            groupId,
            comment_text: this.changeStatus.comment_text,
            reason: this.changeStatus.reason,
          })
          .then(() => {
            this.resetChangeStatus();
          })
          .catch((err) => {
            this.changeStatus.errors = err.response.data.errors;
            this.changeStatus.loading = false;
          });
      }
    },
  },
};
</script>
