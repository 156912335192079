<template>
  <v-dialog v-model="dialog" max-width="600px" @click:outside="resetForm()">
    <v-card>
      <v-form @submit.prevent method="post">
        <v-card-title class="headline"
          >{{ isEditing ? "Edit" : "Add a new" }} Application</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text>Form here...</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
          <v-btn color="accent" text type="submit" :loading="loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    applicationId: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
    };
  },

  methods: {
    openForm: function () {
      // if (application !== null) {
      // }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
    },
  },
};
</script>
