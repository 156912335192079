<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Applications</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Application"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archive Applications</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="grid-row">
            <v-simple-table
              class="mb-5 table-bordered"
              v-for="group in applications_group"
              v-bind:key="group.id"
            >
              <tbody>
                <tr class="grey lighten-3">
                  <td>
                    <div v-if="group.applications.length == 1">Individual</div>
                    <div v-else>Group: {{ group.group_name }}</div>
                  </td>
                  <td colspan="8">
                    Property:
                    {{ group.applications[0].property.property_name }}

                    <span v-if="group.applications.length > 1" class="ml-2">
                      <span
                        v-if="
                          (group.applications[0].property.rent * 30) / 12 <=
                          groupSumSolary(group)
                        "
                      >
                        <v-icon color="green">mdi-check-circle </v-icon>
                      </span>
                      <span v-else>
                        <v-icon color="red">mdi-close-circle </v-icon>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th width="15%">Name</th>
                  <th>Employment</th>
                  <th>Affordability</th>
                  <th>Landlord</th>
                  <th>Pets</th>
                  <th>Smoker</th>
                  <th>Status</th>

                  <th>
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      class="mr-2"
                      @click="$refs.rejectDialog.rejectGroup(group)"
                    >
                      Reject
                    </v-btn>
                  </th>
                </tr>

                <tr
                  v-for="application in group.applications"
                  v-bind:key="application.id"
                >
                  <td
                    v-bind:class="
                      application.interested_in_property == 1
                        ? 'light-blue lighten-5'
                        : ' '
                    "
                  >
                    {{ application.full_name }}
                  </td>
                  <td>{{ application.employment_status }}</td>
                  <td>
                    <div v-if="application.monthly_salary">
                      <div
                        v-if="
                          (group.applications[0].property.rent * 30) / 12 <=
                          application.monthly_salary
                        "
                      >
                        <v-icon color="green">mdi-check-circle </v-icon>
                      </div>
                      <div v-else>
                        <v-icon color="red">mdi-close-circle </v-icon>
                      </div>
                    </div>
                    <div v-else>
                      <v-icon color="yellow">mdi-help-circle</v-icon>
                    </div>
                  </td>
                  <td>
                    <div v-if="application.current_landlord">
                      <v-icon color="green">mdi-check-circle </v-icon>
                    </div>
                    <div v-else>
                      <v-icon color="red">mdi-close-circle </v-icon>
                    </div>
                  </td>
                  <td>
                    <div v-if="application.pets">
                      <v-icon color="green">mdi-check-circle </v-icon>
                    </div>
                    <div v-else>
                      <v-icon color="red">mdi-close-circle </v-icon>
                    </div>
                  </td>
                  <td>
                    <div v-if="application.smoker">
                      <v-icon color="green">mdi-check-circle </v-icon>
                    </div>
                    <div v-else>
                      <v-icon color="red">mdi-close-circle </v-icon>
                    </div>
                  </td>
                  <td>
                    <v-btn
                      x-small
                      depressed
                      :color="
                        application.status.colour +
                        ' ' +
                        (application.status.colour == 'yellow'
                          ? 'black--text lighten-5'
                          : application.status.colour + '--text lighten-5')
                      "
                    >
                      {{ application.status.status_desc }}
                    </v-btn>
                  </td>

                  <td>
                    <v-btn
                      x-small
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      :to="{
                        name: 'module-sbpm-applications-individual',
                        params: { applicationId: application.id },
                      }"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>

                    <v-btn
                      bottom
                      x-small
                      depressed
                      class="mr-2"
                      color="red lighten-4 red--text"
                      @click="openDelete(application.id)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>

                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      class="mr-2"
                      @click="
                        $refs.rejectDialog.changeStatusPrompt(application)
                      "
                    >
                      Reject
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <ApplicationForm ref="applicationForm" />
    <RejectDialog ref="rejectDialog" :fromProperty="false" />

    <v-dialog v-model="deleteApplication.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Application</v-card-title>
        <v-card-text
          >Are you sure you want to archive this application form?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteApplication.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApplicationForm from "./components/ApplicationForm";
import RejectDialog from "../properties/components/RejectDialog.vue";

export default {
  components: {
    ApplicationForm,
    RejectDialog,
  },

  data() {
    return {
      deleteApplication: {
        dialog: false,
        application: {},
        loading: false,
      },
      groups: [],
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    applications_group() {
      let groups = this.$store.getters["sbpm/applicationsStore/groups"];
      let test = groups;

      if (this.searchTerm) {
        let applications = [];
        groups.forEach((s) => {
          s.applications.forEach((application) => {
            if (
              application.full_name.toLowerCase().indexOf(this.searchTerm) !=
                -1 ||
              application.full_name.indexOf(this.searchTerm) != -1
            ) {
              //and if group not added yet

              const index = applications.findIndex(
                (object) => object.id === s.id
              );

              if (index === -1) {
                applications.push(s);
              }
            }
          });
        });
        test = applications;
      }

      return test;
    },
  },

  methods: {
    groupSumSolary(group) {
      let sum = 0;

      group.applications.forEach((application) => {
        if (application.monthly_salary == null) {
          application.monthly_salary = 0;
        }
        sum += parseInt(application.monthly_salary);
      });

      return sum;
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    openDelete(application) {
      this.deleteApplication.application = application;
      this.deleteApplication.dialog = true;
    },

    resetDelete() {
      this.deleteApplication.dialog = false;
      this.deleteApplication.application = {};
      this.deleteApplication.loading = false;
    },

    saveDelete() {
      this.deleteApplication.loading = true;

      this.$store
        .dispatch("sbpm/applicationsStore/deleteApplication", {
          appId: this.$route.params.id,
          applicationId: this.deleteApplication.application,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteApplication.loading = false;
        });
    },
  },
};
</script>
<style>
.table-bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.table-bordered tr td,
.table-bordered tr th {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
</style>
